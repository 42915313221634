<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg">
        <!-- Limit -->
        <div class="form-group">
          <div class="row">
            <div class="col">
              <label
                for="limit-type"
                class="custom-label"
                v-bind:class="{
                  'text-danger': limitError && limitError.type.$error,
                }"
                >{{ $t("limit") }}</label
              >
            </div>
            <div class="col-auto" v-if="limitError && limitError.type.$error">
              <div class="text-danger ml-auto">
                <small>
                  <i>{{ $t("requiredArea") }}</i>
                </small>
              </div>
            </div>
          </div>
          <select
            name="limit-type"
            id="limit-type"
            class="custom-select"
            v-bind:class="{
              'border-danger': limitError && limitError.type.$error,
            }"
            v-model="computedData.type"
            v-on:change="onChangeLimit"
            v-bind:disabled="!limitTypes.length"
          >
            <option value="-1">{{ $t("noLimit") }}</option>
            <option
              v-for="(limit, index) in limitTypes"
              v-bind:key="limit.id"
              v-bind:value="index"
            >
              {{ limit.name }}
            </option>
          </select>
        </div>
        <!-- ./Limit -->
      </div>
      <div class="col-12 col-lg" v-if="computedData.type !== '-1'">
        <!-- Total Price -->
        <div v-if="computedData.type == 0">
          <div class="form-group">
            <label for="limit-price" class="custom-label">{{
              $t("totalAmount")
            }}</label>
            <i
              class="fas fa-question-circle ml-2"
              v-tooltip.right="getTooltip"
            ></i>
            <WeInput v-model="computedData.amount" v-bind:filter-number="true">
              <div slot="append">
                <select class="custom-select rounded-0" v-model="limitCurrency">
                  <option
                    v-for="currency in currencyCodes"
                    v-bind:key="currency.code"
                    v-bind:value="currency.id"
                  >
                    {{ currency.name }}
                  </option>
                </select>
              </div>
            </WeInput>
          </div>
        </div>
        <!-- ./Total Price -->
        <div v-if="computedData.type == 1">
          <!-- Total Usage -->
          <div class="form-group">
            <label for="limit-price" class="custom-label">{{
              $t("usageAmount")
            }}</label>
            <i
              class="fas fa-question-circle ml-2"
              v-tooltip.right="getTooltip"
            ></i>
            <WeInput v-model="computedData.amount" v-bind:filter-number="true">
              <div class="input-group-text" slot="append">
                <i class="fas fa-shopping-cart"></i>
              </div>
            </WeInput>
          </div>
          <!-- ./Total Usage -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LimitInput",
  props: {
    data: null,
    limitError: {
      default: null,
    },
  },
  methods: {
    onChangeLimit() {
      this.computedData.amount = "";
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    ...mapState({
      limitTypes: (state) => state.shared.limitTypes.list,
      currencyCodes: (state) => state.shared.currencyCodes.currencies,
      config: (state) => state.session.config,
    }),
    getTooltip() {
      let text = "";
      if (this.computedData.type == 1) {
        text = this.$t(
          "couponExpiresWhenSalesMadeUsingThisCouponReachTheEnteredQuantity"
        );
      } else {
        text = this.$t(
          "couponExpiresWhenSalesMadeUsingThisCouponReachTheEnteredAmount"
        );
      }

      return text;
    },
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.data = value;
      },
    },
    limitCurrency: {
      get() {
        if (
          this.computedData.currency == "-1" ||
          this.computedData.currency == -1
        ) {
          return this.config["site.currency_code"];
        }
        return this.computedData.currency;
      },
      set(value) {
        this.computedData.currency = value;
      },
    },
  },
};
</script>
